import axios from "axios"
var Config = require('../config.json')
import AuthService from '../service/auth'
var entity='Questionnaire';

const Service = {
    create: function(object) {
	var bearerToken =AuthService.getBearerToken();
		const header = {
			headers: { Authorization: 'Bearer '+bearerToken }
		};
		return axios.post(Config.api_url+'/'+entity,object,header);
    },
    addQuestion: function(question) {
	var bearerToken =AuthService.getBearerToken();
		const header = {
			headers: { Authorization: 'Bearer '+bearerToken }
		};
		return axios.post(Config.api_url+'/'+entity+'/question/'+question.questionnaire,question,header);
    },
    addMultipleQuestions: function(questions) {
	var bearerToken =AuthService.getBearerToken();
		const header = {
			headers: { Authorization: 'Bearer '+bearerToken }
		};
		return axios.post(Config.api_url+'/'+entity+'/question/multiple/'+question.questionnaire,questions,header);
    },
    clone: function(questionnnaire) {
	var bearerToken =AuthService.getBearerToken();
		const header = {
			headers: { Authorization: 'Bearer '+bearerToken }
		};
		return axios.post(Config.api_url+'/'+entity+'/clone/'+questionnnaire.id,questionnnaire,header);
    },
    updateQuestion: function(object) {
		var bearerToken =AuthService.getBearerToken();
		const header = {
			headers: { Authorization: 'Bearer '+bearerToken }
		};
		return axios.put(Config.api_url+'/'+entity+'/question/'+object._id,object,header);
    },
    deleteQuestion: function(object) {
		var bearerToken =AuthService.getBearerToken();
		const header = {
			headers: { Authorization: 'Bearer '+bearerToken }
		};
		return axios.delete(Config.api_url+'/'+entity+'/question/'+object._id,header);
    },
    getAll: function() {
		var bearerToken =AuthService.getBearerToken();
		const header = {
			headers: { Authorization: 'Bearer '+bearerToken }
		};
		return axios.get(Config.api_url+'/'+entity,header);
    },
    getById: function(id) {
		var bearerToken =AuthService.getBearerToken();
		const header = {
			headers: { Authorization: 'Bearer '+bearerToken }
		};
		return axios.get(Config.api_url+'/'+entity+'/'+id,header);
    },
    getByFilter: function(filter) {
		var bearerToken =AuthService.getBearerToken();
		const header = {
			headers: { Authorization: 'Bearer '+bearerToken }
		};
		var qs = serialize(filter);
		var url =Config.api_url+'/'+entity+'/filter/params?'+qs;
		console.log('url',url);
		return axios.get(url,header);
    },
    getTypes: function() {//question types
		var bearerToken =AuthService.getBearerToken();
		const header = {
			headers: { Authorization: 'Bearer '+bearerToken }
		};
		return axios.get(Config.api_url+'/'+entity+'/get/types',header);
    },
    update: function(object) {
		var bearerToken =AuthService.getBearerToken();
		const header = {
			headers: { Authorization: 'Bearer '+bearerToken }
		};
		return axios.put(Config.api_url+'/'+entity+'/'+object._id,object,header);
    },
    deleteById: function(id) {
		var bearerToken =AuthService.getBearerToken();
		const header = {
			headers: { Authorization: 'Bearer '+bearerToken }
		};
		return axios.delete(Config.api_url+'/'+entity+'/'+id,header);
   }
   /*,
  getQuestionsById: function(id) {
		var bearerToken =AuthService.getBearerToken();
		const header = {
			headers: { Authorization: 'Bearer '+bearerToken }
		};
		return axios.get(Config.api_url+'/questions/'+id,header);
    },*/
};

function serialize(obj) {
	var str = [];
	for (var p in obj)
	if (obj.hasOwnProperty(p)) {
		str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
	}
	return str.join("&");
}
		
export default Service;