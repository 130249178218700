import {useEffect,useState} from 'react';
import { useNavigate,useParams } from "react-router-dom";
import Button from '@mui/material/Button';

import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import Divider from '@mui/material/Divider';
import AddIcon from '@mui/icons-material/Add';
import SettingsIcon from '@mui/icons-material/Settings';

import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import Box, { BoxProps } from '@mui/material/Box';

import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';

import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import IconButton from '@mui/material/IconButton';

import TopPrivateNavigation from '../components/TopPrivateNavigation.jsx'
import AppLogout from '../components/AppLogout.jsx'

//services
import AuthService from '../service/auth'
///projects 
import ProjectService from '../service/Project'
import AccountProjectService from '../service/AccountProject'
import ClientService from '../service/Client'

const formatJson=function(json){
	return JSON.stringify(json);
}

const Project = () => {
	
	const navigate = useNavigate();
	//protected page check if logged in
	const [currentUser,setCurrentUser]=useState({});
	const [projectData,setProjectData]=useState([]);
	const [projectFound,setProjectFound]=useState(false);
	
	//let params = useParams();
	let project_id =ProjectService.getCurrentProject();//params.id;
	console.log('project_id',project_id);
	
		//master/researcher cards 
	const [dashboardCards,setDashboardCards]=useState({
		"Master":{
			"cards":[
				{"name":"Researchers","link":"/researchers"},
				{"name":"Clients","link":"/clients"},
				{"name":"Foods","link":"/foods"},
				{"name":"Recipes","link":"/recipes"},
				{"name":"Exports","link":"/exports"},
				{"name":"Questionnaires","link":"/Questionnaires"},
			]
		},
		"Researcher":{
			"cards":[
				{"name":"Clients","link":"/clients"},
				{"name":"Foods","link":"/foods"},
				{"name":"Recipes","link":"/recipes"},
				
			]
		}
		
	});
		
	useEffect(() => {
		if(!AuthService.isUserLoggedIn()){
			// not logged in navigate back to login
			navigate("/login");
		}else{
				setCurrentUser(AuthService.getCurrentUser());
				var account_type=AuthService.getCurrentUser().__t;
				console.log('account_type',account_type);
				
				console.log(AuthService.getCurrentUser());
				var currentUser=AuthService.getCurrentUser();
				//expired Account
				if(currentUser.expired){
					var string = "account_type="+AuthService.getCurrentUser().__t+"&type=expired";
					console.log('string',string);
					var encodedString = btoa(string); // Base64 encode the String
					console.log('encodedString',encodedString);
					navigate("/restricted/"+encodedString);
					
				}else{//unverified account
					if(!currentUser.email_verified){
						var string = "account_type="+AuthService.getCurrentUser().__t+"&type=emaiverification";
						console.log('string',string);
						var encodedString = btoa(string); // Base64 encode the String
						console.log('encodedString',encodedString);
						navigate("/restricted/"+encodedString);
						
					}else{////force change Password
						if(currentUser.force_password_change){
								
						
						}else{
							//load
							if(account_type=='Admin'){//Admin
						
							}else if(account_type=='Master'){//Master
								
								loadProjectById(project_id);
								//loadClientByProjectId(project_id);
								//loadResearchersByProjectId(project_id);
								
							}else if(account_type=='Researcher'){//Researcher
								loadProjectById(project_id);
							}
						}
					}
				}	
		}
	}, []);
		//project settings
		const [ShowErrorModalMessage,setErrorModalStatus]=useState(false); 
		const [ErrorModalText,setErrorModalMessage]=useState(""); 
	
		const [ShowSuccessModalMessage,setSuccessModalStatus]=useState(false); 
		const [SuccessModalText,setSuccessModalMessage]=useState("");
		
		const [openEditSettingsModal, setEditSettingsModal] = useState(false);//closed
		const [tempProjectSettings,setTempProjectSettings]=useState({}); 
		const [tempProjectSettingsTemplate,setTempProjectSettingsTemplate]=useState([]); 
		const handleEditSettingsModal = (e) => {
			console.log('project....',projectData.settings);
			setTempProjectSettings(projectData.settings);
			console.log('project....',projectData.settings_template);
			setTempProjectSettingsTemplate(projectData.settings_template);
			setEditSettingsModal(true);
		};

		const handleEditSettingsModalOpen = () => {
			setEditSettingsModal(true);
		};

		const handleEditSettingsModalClose = () => {
			setEditSettingsModal(false);
		};
		
		const handleSettingsChange = (e,key) => {
			setTempProjectSettings((prevState) => ({ ...prevState, [key]: e.target.value }));
		};
		
		const handleSubmitEditSettings=(event: React.FormEvent<HTMLFormElement>) => {
			event.preventDefault();
			const data = new FormData(event.currentTarget);
			console.log(tempProjectSettings);
			projectData.settings=tempProjectSettings;
			ProjectService.update(projectData).then(function(res){
				//setProjectData(res.data);
				console.log(res.data);
				setProjectFound(true);
				loadProjectById(project_id);
				setEditSettingsModal(false);
			}).catch(function(error){
					console.log(error);
					setProjectFound(false);
			});
			
			/*var project={'name':data.get('name'),
						'description':data.get('description'),
						'created_by':AuthService.getCurrentUser()._id
						};
			console.log('project',project);
			if((project.name)&&(project.description)){	
				createProjects(project);
				setNewProjectModalOpen(false);		
			}else{
				setErrorStatus(true);
				setErrorMessage("Please ensure all required fields are completed correctly!!");
			}*/
		};
		
		
		
		const handleTestAddClient = (e) => {
			//		createClient({'project':project_id,'sex':'M','date_of_birth':new Date('1982-01-10')});
			createClient({'project':project_id,'sex':'M',});
			console.log('creating....');
		};
		
		function loadProjectById(project_id){
			ProjectService.getById(project_id).then(function(res){
				setProjectData(res.data);
				console.log(res.data);
				setProjectFound(true);
			}).catch(function(error){
					console.log(error);
					setProjectFound(false);
			});
		}
	  
	    function loadClientByProjectId(project_id){
			ClientService.getByFilter({'project':project_id}).then(function(res){
				//setClientData(res.data);
				console.log(res.data);
			});
		}
	  
		function createClient(client){
			ClientService.create(client).then(function(res){
				console.log(res.data);
				loadClientByProjectId(project_id)

			}).catch(function(error){
					console.log(error);
			});
		};
		
		function loadResearchersByProjectId(project_id){
			AccountProjectService.getByFilter({'project':project_id,'account_type':'Researcher'}).then(function(res){
				console.log(res.data);
			}).catch(function(error){
					console.log(error);
			});
		};
		
  return(
  <>
	<TopPrivateNavigation/>
	<AppLogout>
	
	{/* error no project found */}
	{(!projectFound) ?
		<>
		
		
		</>
	:<></>}
	{/* if project is found */}
	{(projectFound) ?
		<>
		<br/>
		<Breadcrumbs aria-label="breadcrumb">
        <Link underline="hover" color="inherit" href="/">
          Home
        </Link>
        <Link
          underline="hover"
          color="inherit"
          href="/projects"
        >
          Projects
        </Link>
        <Typography color="text.primary">{projectData.name}</Typography>
      </Breadcrumbs>
	  
	  <br/>
	  <Divider />
	<div style={{ width: '100%' }}>	
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row-reverse',
          p: 1,
          m: 1,
         // bgcolor: 'background.paper',
          borderRadius: 1,
        }}
      >
	  {(currentUser.__t=='Master') ?
		<>
        <Button /*onClick={(e) => handleEditSettingsModal(e)}*/ href={`/project/settings/${project_id}`}  variant="outlined" startIcon={<SettingsIcon/>}>
		  Settings
		</Button>
		</>:<></>}
		</Box>
    </div>
	<h3>
		{projectData.name}
		<IconButton disabled aria-label="settings">
			{(projectData.is_private) ?
			<>
				<LockIcon />
			</>:<>
				<LockOpenIcon />
			</>
			}
			</IconButton>
	</h3>
	<i style={{ color: '#ccc',fontWeight:'Normal' }}>{projectData.description}</i>
	<Grid container>
  <Grid item sm={12}>
    <Box display="flex" justifyContent="flex-end">
      <b>Created by : </b> {projectData.created_by.first_name} {projectData.created_by.last_name}
    </Box>
  </Grid>
</Grid>
		
	</>
	:<></>}

	{/* Admin */}
	{(currentUser.__t=='Admin') ?
	<>
	
	</>
	:<></>}
	
	{/* Master */}
	{(currentUser.__t=='Master') ?
	<>
	
	<br/><br/>
	  <Grid container spacing={3}>
	  {dashboardCards[currentUser.__t].cards.map((row,index) => (
		   <Grid key={row.name} item item xs={12} sm={6} md={4}>
			    <Card  variant="outlined" sx={{ minWidth: 275 }}>
				  <CardContent style={{ textAlign: 'center' }} >
					<Typography variant="h5" component="div">
					{row.name}
					</Typography>
					<Typography sx={{ mb: 1.5 }} color="text.secondary">
					  
					</Typography>
				  </CardContent>
				  <CardActions style={{justifyContent:'center'}}>
					<Button size="small" href={`${row.link}`} >View all</Button>
				  </CardActions>
			</Card>
		</Grid>
	  
	  ))}
	  </Grid>
	
	</>
	:<></>}
	
	{/* Researcher */}
	{(currentUser.__t=='Researcher') ?
	<>
	
	<br/> <br/>
	  <Grid container spacing={3}>
	  {dashboardCards[currentUser.__t].cards.map((row,index) => (
		   <Grid key={row.name} item item xs={12} sm={6} md={4}>
			    <Card  variant="outlined" sx={{ minWidth: 275 }}>
				  <CardContent style={{ textAlign: 'center' }} >
					<Typography variant="h5" component="div">
					{row.name}
					</Typography>
					<Typography sx={{ mb: 1.5 }} color="text.secondary">
					  
					</Typography>
				  </CardContent>
				  <CardActions style={{justifyContent:'center'}}>
					<Button size="small" href={`${row.link}`} >View all</Button>
				  </CardActions>
			</Card>
		</Grid>
	  
	  ))}
	  </Grid>
	
	</>
	:<></>}
	
	

	</AppLogout>
	</>
  ) 
  ;
};

export default Project;