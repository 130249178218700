import {useEffect,useState} from 'react';
import { useNavigate,useParams } from "react-router-dom";

import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import Divider from '@mui/material/Divider';

import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';
import SaveIcon from '@mui/icons-material/Save';
import EditIcon from '@mui/icons-material/Edit';
import SettingsIcon from '@mui/icons-material/Settings';
import PublishIcon from '@mui/icons-material/Publish';
import ClearIcon from '@mui/icons-material/Clear';
import {styled } from '@mui/material/styles';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';

import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import FormLabel from '@mui/material/FormLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import Accordion from '@mui/material/Accordion';
import AccordionActions from '@mui/material/AccordionActions';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';

//import { styled } from '@mui/material/styles';
//import { Box } from "@material-ui/core";

import TopPrivateNavigation from '../components/TopPrivateNavigation.jsx'
import AppLogout from '../components/AppLogout.jsx'

//services
import AuthService from '../service/auth'
import ProjectService from '../service/Project'
import QuestionnaireService from '../service/Questionnaire'

const formatPageMethod=function(word) {
  return word.charAt(0).toUpperCase()+ word.slice(1);
}

const formatJson=function(j){
	return JSON.stringify(j);
}

const numberToLetter=function(i){
	return ((i+1) + 9).toString(36);
}

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'left',
  color: theme.palette.text.secondary,
  ...theme.applyStyles('dark', {
    backgroundColor: '#1A2027',
  }),
}));



const Questionnaire = () => {
	
	const navigate = useNavigate();
	//protected page check if logged in
	const [currentUser,setCurrentUser]=useState({});
	const [projectData,setProjectData]=useState([]);
	const [projectFound,setProjectFound]=useState(false);
	
	const [pageMethod,setPageMethod]=useState("");
	const [questionnaireData,setQuestionnaireData]=useState({'title':'','description':''});
	const [tempQuestionnaireData,setTempQuestionnaireData]=useState({'title':'', 'description':''});
	const [questionnaireQuestions,setQuestionnaireQuestions]=useState([]);
	const [tempQuestionnaireQuestions,setTempQuestionnaireQuestions]=useState([]);
	//question
	const [tempQuestion,setTempQuestion]=useState({'question':'', 'type':'','options':[],'settings':{}});
	const [tempQuestionSettings,setTempQuestionSettings]=useState({});
	const [tempQuestionChoices,setTempQuestionChoices]=useState([]);
	//let params = useParams();
	let project_id =ProjectService.getCurrentProject();//params.id;
	console.log('project_id',project_id);
	
	let params = useParams();
	//page method 
	let method =formatPageMethod(params.method);//page method
	console.log('method',method);
	///questionnaire id 
	let questionnaire_id =params.questionnaire_id;
	console.log('questionnaire',questionnaire_id);
		
	useEffect(() => {
		if(!AuthService.isUserLoggedIn()){
			// not logged in navigate back to login
			navigate("/login");
		}else{
				setCurrentUser(AuthService.getCurrentUser());
				var account_type=AuthService.getCurrentUser().__t;
				console.log('account_type',account_type);
				
				console.log(AuthService.getCurrentUser());
				var currentUser=AuthService.getCurrentUser();
				//expired Account
				if(currentUser.expired){
					var string = "account_type="+AuthService.getCurrentUser().__t+"&type=expired";
					console.log('string',string);
					var encodedString = btoa(string); // Base64 encode the String
					console.log('encodedString',encodedString);
					navigate("/restricted/"+encodedString);
					
				}else{//unverified account
					if(!currentUser.email_verified){
						var string = "account_type="+AuthService.getCurrentUser().__t+"&type=emaiverification";
						console.log('string',string);
						var encodedString = btoa(string); // Base64 encode the String
						console.log('encodedString',encodedString);
						navigate("/restricted/"+encodedString);
						
					}else{////force change Password
						if(currentUser.force_password_change){
							//not implemented	
						
						}else{
							setPageMethod(method);
							//load project if set 
							if((typeof project_id !=="undefined")&&(project_id!==null)){//project filter
								loadProjectById(project_id);
							}
							
							//load
							if(account_type=='Admin'){//Admin
						
							}else if(account_type=='Master'){//Master
								loadQuestionnaireTypes();
								if(method=='Edit'){
									loadQuestionnaireById(questionnaire_id);
								}else if(method=='View') {
									loadQuestionnaireById(questionnaire_id);
								}else if(method=='New') {
									loadQuestionnaireTemplate();
								}else if(method=='Clone') {
									loadQuestionnaireById(questionnaire_id);
								}
								
							}else if(account_type=='Researcher'){//Researcher
								
							}
						}
					}
				}	
		}
	}, []);
	
	/*Notifications  */
	const [ShowErrorMessage,setErrorStatus]=useState(false); 
	const [ErrorText,setErrorMessage]=useState(""); 
	
	const [ShowSuccessMessage,setSuccessStatus]=useState(false); 
	const [SuccessText,setSuccessMessage]=useState("");
	
	function clearMessages(){
		setErrorStatus(false);
		setErrorMessage("");
		setSuccessStatus(false);
		setSuccessMessage("");
	}
	
	function createErrorMessage(msg){
		clearMessages();
		setErrorStatus(true);
		setErrorMessage(msg);
	}
	
	function createSuccessMessage(msg){
		clearMessages();
		setSuccessStatus(true);
		setSuccessMessage(msg);
	}
	
	const renderAlertBar=() => {
		return <div style={{ width: '100%' }}>
					<Box
						sx={{display: 'flex',flexDirection: 'row-reverse',p: 1,m: 1,borderRadius: 1,
						}}
					>
					<Stack sx={{ width: '100%' }} spacing={2}>
						{ ShowErrorMessage &&
							<Alert severity="error">{ErrorText}</Alert>
							} 
						{ ShowSuccessMessage &&
							<Alert severity="success">{SuccessText}</Alert>
						}
						</Stack>
					</Box>
			</div>
	};
	
	//load project 
	function loadProjectById(project_id){
		ProjectService.getById(project_id).then(function(res){
		setProjectData(res.data);
		console.log(res.data);
		setProjectFound(true);
		}).catch(function(error){
			console.log(error);
			setProjectFound(false);
		});
	}
	
	//load questionnaire 
	function loadQuestionnaireById(questionnaire_id){
		QuestionnaireService.getById(questionnaire_id).then(function(res){
		setQuestionnaireData(res.data.questionnaire);
		setTempQuestionnaireData(res.data.questionnaire);
		setQuestionnaireQuestions(res.data.questions);
		setTempQuestionnaireQuestions(res.data.questions);
		createTempResponses(res.data.questions);
		console.log(res.data);
		}).catch(function(error){
			console.log(error);
		});
	}
	
	function loadQuestionnaireTemplate(){
		var questionnaire={
			'title':'',
		    'description':''
		}
		if((typeof project_id !=="undefined")&&(project_id!==null)){
			questionnaire.project=project_id;
			questionnaire.global=false;
		}else{
			questionnaire.project=null;
			questionnaire.global=true;
		}
		questionnaire.created_by=AuthService.getCurrentUser()._id;
		setQuestionnaireData(questionnaire);
		setTempQuestionnaireData(questionnaire);
	}
	
	const [defaultQuestionType,setDefaultQuestionTypes]=useState({});
	const [questionTypes,setQuestionTypes]=useState([]);
	const [questionTypesObject,setQuestionTypesObject]=useState({});
	function loadQuestionnaireTypes(){
		QuestionnaireService.getTypes().then(function(res){
			let _questionTypes =res.data.array;
			setQuestionTypes(_questionTypes);
			setQuestionTypesObject(res.data.obj);
			if(_questionTypes.length>0){
				setDefaultQuestionTypes(_questionTypes[0]);
			}
			console.log(res.data);
		}).catch(function(error){
			console.log(error);
		});
	}
	
	const handleOnChangeValue = (e,key) => {
		console.log(e.target.value,key);
		setTempQuestionnaireData({...tempQuestionnaireData,
			[key]: e.target.value,
		})
	};
	
	//handleOnChangeQuestionValue
	const handleOnChangeQuestionValue = (e,key) => {
		setTempQuestion({...tempQuestion,
			[key]: e.target.value,
		})
	};
	
	const handleOnChangeQuestionType = (e) => {
		let _type=e.target.value;
				console.log('type',_type);
			console.log('question type object:',questionTypesObject[_type].default_settings)
		setTempQuestion({...tempQuestion,
			['type']: _type,
		})
		setTempQuestionSettings(generateSettings(questionTypesObject[e.target.value].default_settings));
	};
	
	const handleOnChangeQuestionSettings = (e,key) => {
		console.log(e.target.value,key);
		setTempQuestionSettings({...tempQuestionSettings,
			[key]:e.target.value,
		})
	};
	
	function generateSettings(_def,){
		var _settings ={};
		for(let i=0;i<_def.length;i++){
				_settings[_def[i].id]=_def[i].value;
		}
		return _settings;
	}
	
	const handleAddingNewQuestion = (e) => {
		let question={
			question: '',
			choices: [''],
			questionnaire: questionnaire_id,
			//options: { type: Object, required: false,default:[] },//multiple select and single select 
			type: defaultQuestionType.id,//setting max length 
			settings: generateSettings(defaultQuestionType.default_settings),//setting max length 
		}
		question.created_by=AuthService.getCurrentUser()._id;
		console.log('question',question);
		QuestionnaireService.addQuestion(question).then(function(res){
			console.log(res.data);
			loadQuestionnaireById(questionnaire_id);
			var _question=res.data;
			
			setTempQuestion(_question);
			setTempQuestionSettings(_question.settings);
			setTempQuestionChoices(_question.choices);
			
			//reset all others
			for (var _id in loadingId) {
				setLoadingId(ids => ({
					 ...ids,
					[_id]: false
				}));
			}
			setLoadingId(ids => ({
				 ...ids,
				[_question.id]: true
			}));
		}).catch(function(error){
			console.log(error);
		});
	};
	
	const [loadingId, setLoadingId] = useState({});
	const checkLoadingId=function(id){
		return loadingId[id];
	};
	

	const handleCancelEdit=(e,question) => {

		const { id } = e.target;
			setLoadingId(ids => ({
			 ...ids,
			[id]: false
		}));
	};
	
	const handleViewQuesionnaireSubmit=(e) => {

		
	};
	
	const handleSaveMode=(e,question) => {
			//save mode 
			console.log('save changes');
	 const { id } = e.target;
			setLoadingId(ids => ({
			 ...ids,
			[id]: false
		}));
		console.log('question',question);
		console.log('tempQuestion',tempQuestion);
		console.log('tempQuestionSettings',tempQuestionSettings);
		tempQuestion.settings=tempQuestionSettings;
		tempQuestion.choices=tempQuestionChoices;

		QuestionnaireService.updateQuestion(tempQuestion).then(function(res){
			console.log(res.data);
			loadQuestionnaireById(questionnaire_id);

		}).catch(function(error){
			console.log(error);
		});
	};
	
	const handleEditMode=(e,question) => {
		setTempQuestion(question);
		setTempQuestionSettings(question.settings);
		setTempQuestionChoices(question.choices);
		//reset all others
		for (var _id in loadingId) {
			setLoadingId(ids => ({
				 ...ids,
				[_id]: false
			}));
		}
		//
		 const { id } = e.target;
			setLoadingId(ids => ({
			 ...ids,
			[id]: true
		}));
	};
	
	/*choice*/
	const handleAddChoice=(e) => {
		console.log(e);
		const newChoiceList = [...tempQuestionChoices];
		newChoiceList.push("");
		setTempQuestionChoices(newChoiceList);
	}
	
	const handleRemoveChoice=(e,index) => {
		console.log(e,index);
			
		const newChoiceList = [...tempQuestionChoices];
		newChoiceList.splice(index, 1);
		setTempQuestionChoices(newChoiceList);
	}
	
	const handleOnChangeQuestionChoices = (e,index) => {
		console.log(e.target.value,index);
		
		const newChoiceList = [...tempQuestionChoices];
		newChoiceList[index]=e.target.value;
		setTempQuestionChoices(newChoiceList);
		
	};
	
	/* Delete Question  */
	const [openDeleteQuestionModal, setOpenDeleteQuestionModal] = useState(false);
	
	const handleDeleteQuestionClose = () => {
		setOpenDeleteQuestionModal(false);
	  };
	  
	const handleDeleteQuestionModal=(e,question) => {
		setTempQuestion(question);
		setOpenDeleteQuestionModal(true);
	}
	
	const handleDeleteQuestion=(e) => {
		QuestionnaireService.deleteQuestion(tempQuestion).then(function(res){
			console.log(res.data);
			loadQuestionnaireById(questionnaire_id);
			setOpenDeleteQuestionModal(false);
		}).catch(function(error){
			console.log(error);
		});
	}
	
	const handleSubmitQuestionnaire=(e,m) => {

		console.log('e',e);
		console.log('method',m);
		
		if(m=='New'){
			console.log('tempQuestionnaireData',tempQuestionnaireData);
			
			if(tempQuestionnaireData.title!==''){
				QuestionnaireService.create(tempQuestionnaireData).then(function(res){
					console.log(res.data);
					navigate("/questionnaire/edit/"+res.data._id);
					navigate(0);//refresh page
				}).catch(function(error){
					console.log(error);
					
				});
			}else{
				createErrorMessage("Please give questionnaire a title");
				//console.log('no name set !!!');
			}
			
		}
		if(m=='Edit'){
			if(tempQuestionnaireData.title!==''){
				QuestionnaireService.update(tempQuestionnaireData).then(function(res){
					console.log(res.data);
					loadQuestionnaireById(questionnaire_id);
					createSuccessMessage("Questionnaire updated");
				}).catch(function(error){
					console.log(error);
				});
			}else{
				createErrorMessage("Please give questionnaire a title");
				//console.log('no name set !!!');
			}
		}
		if(m=='Publish'){
			if(tempQuestionnaireData.title!==''){
				tempQuestionnaireData.published=true;
				QuestionnaireService.update(tempQuestionnaireData).then(function(res){
					console.log(res.data);
					loadQuestionnaireById(questionnaire_id);
					createSuccessMessage("Questionnaire published.");
				}).catch(function(error){
					console.log(error);
				});
			}else{
				createErrorMessage("Please give questionnaire a title");
				//console.log('no name set !!!');
			}
		}
		if(m=='View'){
			//edit
			navigate("/questionnaire/edit/"+questionnaire_id);
			navigate(0);//refresh page
		}
		
		if(m=='Clone'){
			//update then redirect to view or edit with alert
			
			/*if(tempQuestionnaireData.title!==''){
				QuestionnaireService.addQuestion(tempQuestionnaireData).then(function(res){
					console.log(res.data);
					loadQuestionnaireById(questionnaire_id);
					createSuccessMessage("Questionnaire updated");
				}).catch(function(error){
					console.log(error);
				});
			}else{
				createErrorMessage("Please give questionnaire a title");
				//console.log('no name set !!!');
			}*/
			
		}
	};
	
	//View
		const [tempResponses,setTempReponses]=useState({});
		const handleOnChangeResponseValue = (e,question) => {
		console.log(e);
		console.log(question);
		let question_id=question.id;
		let type=question.type;
		
		console.log(tempResponses);
		console.log(tempResponses[question_id]);
		if(type=='multi_choice'){
			let value= e.target.value;
			var _temp =tempResponses[question_id];
			if(e.target.checked){//add
				_temp.push(value);
			}else{//remove
				var index = _temp.indexOf(value);
				if (index !== -1) {
				  _temp.splice(index, 1);
				}
			}
			setTempReponses({...tempResponses,
				[question_id]: _temp,
			})
		}else if(type=='time'){
			let value= e.$d;
			setTempReponses({...tempResponses,
				[question_id]: [value],
			})
			
		}else if(type=='date'){
					let value= e.$d;
			setTempReponses({...tempResponses,
				[question_id]: [value],
			})
			
		}else if(type=='comment'){
				let value= e.target.value;
			setTempReponses({...tempResponses,
				[question_id]: [value],
			})
			
		}else if(type=='text'){
				let value= e.target.value;
			setTempReponses({...tempResponses,
				[question_id]: [value],
			})
			
		}else{
			let value= e.target.value;
			setTempReponses({...tempResponses,
				[question_id]: [value],
			})
			
		}

		console.log(tempResponses[question_id]);
	};
	
	function createTempResponses(questions){
		var responses={};
		for(let i=0;i<questions.length;i++){
			responses[questions[i].id]=[];
		}
		setTempReponses(responses);
	}
	
  return(
  <>
	<TopPrivateNavigation/>
	<AppLogout>
	
		{/* error no project found */}
	{(!projectFound) ?
		<>
		
		<br/>
		<Breadcrumbs aria-label="breadcrumb">
        <Link underline="hover" color="inherit" href="/">
          Home
        </Link>
		<Link
          underline="hover"
          color="inherit"
          href={`/Questionnaires`}
        >
          Questionnaires
        </Link>
		 <Typography color="text.primary">{method}</Typography>
      </Breadcrumbs>
	  
	  <br/>
	  <Divider />
		<br/>
		
		
		</>
	:<></>}
	{/* if project is found */}
	{(projectFound) ?
	<>
		<br/>
		<Breadcrumbs aria-label="breadcrumb">
        <Link underline="hover" color="inherit" href="/">
          Home
        </Link>
        <Link
          underline="hover"
          color="inherit"
          href="/projects"
        >
          Projects
        </Link>
		<Link
          underline="hover"
          color="inherit"
          href={`/project/${projectData._id}`}
        >
          {projectData.name}
        </Link>
		<Link
          underline="hover"
          color="inherit"
          href={`/Questionnaires`}
        >
          Questionnaires
        </Link>
		 <Typography color="text.primary">{method}</Typography>
      </Breadcrumbs>
	  
	  <br/>
	  <Divider />
		<br/>
	
	</>
	:<></>}
	
	{/* Admin */}
	{(currentUser.__t=='Admin') ?
	<>
	
	</>
	:<></>}
	
	{/* Master */}
	{(currentUser.__t=='Master') ?
	<>
	
	 <Dialog
        open={openDeleteQuestionModal}
        onClose={handleDeleteQuestionClose}
        //aria-labelledby="alert-dialog-title"
		//aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Are you sure you want to delete this question?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
		  
          </DialogContentText>
        </DialogContent>
        <DialogActions>
			<Button onClick={handleDeleteQuestionClose}>No</Button>
			<Button onClick={handleDeleteQuestion} > Yes</Button>
        </DialogActions>
      </Dialog>
	  
	  {/* notification alert bar*/}
	  {renderAlertBar()}

		{/* New */}
		{(pageMethod=='New') ?
		<>
		<h4>Title</h4>
		<TextField  margin="normal" required fullWidth id="title" label="Title" name="title"
			value={tempQuestionnaireData['title']}
			onChange={(e) => {handleOnChangeValue(e,'title')}}
		/>
	
			{/*<h4>Description</h4>
		<TextField  id="description"  label="Description" name="Description"  multiline rows={3}  sx={{ width: '100%' }}
			value={tempQuestionnaireData['description']}
			onChange={(e) => {handleOnChangeValue(e,'description')}}
		/>*/}
		
		<div style={{ width: '100%' }}>
			<Box sx={{ display: 'flex', flexDirection: 'row-reverse',  p: 1,  m: 1, borderRadius: 1, }} >	
				<Button onClick={(e) => handleSubmitQuestionnaire(e,'New')}  variant="contained" startIcon={<SaveIcon/>}>
				  Create
				</Button>
			</Box>
		</div>
	
		</>
		:<></>}
		
		{/* Edit */}
		{(pageMethod=='Edit') ?
		<>
		
		{tempQuestionnaireData.published==false &&
		<>
		<div style={{ width: '100%' }}>
			<Box sx={{ display: 'flex', flexDirection: 'row-reverse',  p: 1,  m: 1, borderRadius: 1, }} >	
				<Button onClick={(e) => handleSubmitQuestionnaire(e,'Publish')}  variant="contained" startIcon={<PublishIcon/>}>
				  Publish
				</Button>
			</Box>
		</div>
		</>}

		<h4>Title</h4>
		<TextField  margin="normal" required fullWidth id="title" label="Title" name="title"
			value={tempQuestionnaireData['title']}
			onChange={(e) => {handleOnChangeValue(e,'title')}}
		/>
	
	{/*<h4>Description</h4>
		<TextField  id="description"  label="Description" name="Description"  multiline rows={3}  sx={{ width: '100%' }}
			value={tempQuestionnaireData['description']}
			onChange={(e) => {handleOnChangeValue(e,'description')}}
		/>*/}
		<div style={{ width: '100%' }}>
			<Box sx={{ display: 'flex', flexDirection: 'row-reverse',  p: 1,  m: 1, borderRadius: 1, }} >	
				<Button onClick={(e) => handleSubmitQuestionnaire(e,'Edit')}  variant="contained" startIcon={<SaveIcon/>}>
				  Update
				</Button>
				<br/>
			</Box>
		</div>
		
		<h4>Questions</h4>
		{tempQuestionnaireQuestions.length==0 &&
				<><h5>No questions</h5></>
		}
		
		{tempQuestionnaireQuestions.length>0 &&
		<>
		
			<Box sx={{ flexGrow: 1 }}>
			  <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
				{tempQuestionnaireQuestions.map((row,index) => (
				  <Grid item xs={12} sm={12} md={12} key={index}>
					<Item>
						<h4>Question {index+1}) </h4>
						{checkLoadingId(row._id) &&
						<>
							
							<TextField  margin="none" required fullWidth id="question" label="Question" name="question"
								value={tempQuestion['question']}
								onChange={(e) => {handleOnChangeQuestionValue(e,'question')}}
							/>
						<br/>
						<br/>
						<FormControl sx={{ m: 1, width: '50%'}}>
							  <InputLabel id="demo-select-large-label">Question Type</InputLabel>
							  <Select
								labelId="demo-select-small-label"
								id="demo-select-small"
								value={tempQuestion['type']}
								label="QuestionType"
								onChange={(e) => handleOnChangeQuestionType(e)}
							  >
							  {questionTypes.map((t,index) => (
								  <MenuItem key={t.id} value={t.id}>{t.name}</MenuItem>
							  ))}
							  </Select>
						</FormControl>
						<br/>
						
							
							{tempQuestion.type=='text' &&
							<>
								
							</>}
							
							{tempQuestion.type=='number' &&
							<>
								
							</>}
							
							{tempQuestion.type=='date' &&
							<>
								
							</>}
							
							{tempQuestion.type=='time' &&
							<>
								
							</>}
							{tempQuestion.type=='comment' &&
							<>
							
							</>}
							
							{tempQuestion.type=='single_choice' &&
							<>
								<br/>
								{tempQuestionChoices.map((choice,index) => (
									<> {numberToLetter(index)})&nbsp;
									<TextField  inputProps={{ type: 'text'}} size="small"  margin="dense" id="" label="" name=""
											value={tempQuestionChoices[index]}
											onChange={(e) => {handleOnChangeQuestionChoices(e,index)}}
										/>
									<IconButton aria-label="remove" onClick={(e) => handleRemoveChoice(e,index)} > <ClearIcon sx={{color: "#ccc",fontSize: 16 }} /></IconButton>					<br/>
									</>
								))}
								<IconButton aria-label="add" onClick={(e) => handleAddChoice(e)} >
									<AddIcon sx={{color: "#ccc",fontSize: 16 }} />
								</IconButton>&nbsp;Add
							</>}
							
							{tempQuestion.type=='multi_choice' &&
							<>
								<br/>
								{tempQuestionChoices.map((choice,index) => (
									<> {numberToLetter(index)})&nbsp;
									<TextField  inputProps={{ type: 'text'}} size="small"  margin="dense" id="" label="" name=""
											value={tempQuestionChoices[index]}
											onChange={(e) => {handleOnChangeQuestionChoices(e,index)}}
										/>
									<IconButton aria-label="remove" onClick={(e) => handleRemoveChoice(e,index)} > <ClearIcon sx={{color: "#ccc",fontSize: 16 }} /></IconButton>					<br/>
									</>
								))}
								<IconButton aria-label="add" onClick={(e) => handleAddChoice(e)} >
									<AddIcon sx={{color: "#ccc",fontSize: 16 }} />
								</IconButton>&nbsp;Add
							</>}
						
						{questionTypesObject[tempQuestion.type].default_settings.length>0 &&
						 <div>
						  <Accordion>
							<AccordionSummary
							  expandIcon={<ExpandMoreIcon />}
							  aria-controls="panel1-content"
							  id="panel1-header"
							>
							<SettingsIcon sx={{color: "#ccc",fontSize: 18 }} /> &nbsp; Settings
							</AccordionSummary>
							<AccordionDetails>
							
							{questionTypesObject[tempQuestion.type].default_settings.map((_settings,index) => (
										<>
										<h5>{_settings.name}</h5>
										<TextField  inputProps={{ type: _settings.type}} size="small"  margin="none" id="" label="" name=""
											value={tempQuestionSettings[_settings.id]}
											onChange={(e) => {handleOnChangeQuestionSettings(e,_settings.id)}}
										/>
										</>
								))}
							</AccordionDetails>
						  </Accordion>
						 
						</div>
						}
					
							
							
							<br/><br/>
							
							<Button id={row._id} size="small"  variant="contained"  onClick={(e) => handleCancelEdit(e,row)} >
								Cancel
							</Button>
							&nbsp;
							<Button id={row._id} size="small"  variant="contained"  onClick={(e) => handleSaveMode(e,row)} >
								Save
							</Button>
						</>
						} 
						{!checkLoadingId(row._id) &&
						<>
						<h4>{row.question}</h4>
						
						
						<Button id={row._id} size="small"  variant="contained"  onClick={(e) => handleEditMode(e,row)} >
							Edit
						</Button>
						</>
						} 
						&nbsp;
						<Button id={row._id} size="small"  variant="contained"  onClick={(e) => handleDeleteQuestionModal(e,row)} >
							Delete
						</Button>
					
					</Item>
				  </Grid>
				))}
			  </Grid>
			</Box>
		
		
		
		
		</>
		
		}
		<br/>
			<Button onClick={(e) => handleAddingNewQuestion(e,)}  variant="contained" startIcon={<AddIcon/>}>
				  add question
			</Button>
		<br/><br/><br/><br/><br/><br/><br/>
		</>
		:<></>}

		{/* View */}
		{(pageMethod=='View') ?
		<>
			{tempQuestionnaireData.published==false &&
			<>
				<div style={{ width: '100%' }}>
						<Box sx={{ display: 'flex', flexDirection: 'row-reverse',  p: 1,  m: 1, borderRadius: 1, }} >	
							<Button onClick={(e) => handleSubmitQuestionnaire(e,'View')}  variant="contained" startIcon={<EditIcon/>}>
							  Edit
							</Button>
						</Box>
					</div>
			</>}
			<h4>{tempQuestionnaireData.title}</h4>
				{/*<p>{tempQuestionnaireData.description}</p>*/}
			<h5>Questions</h5>

			<Box sx={{ flexGrow: 1 }}>
			  <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
				{tempQuestionnaireQuestions.map((q,index) => (
				  <Grid item xs={12} sm={12} md={12} key={index}>
					<Item>{index+1}) {q.question}
					<br/>
							 {q.type=='text' &&
			  <>
				<TextField  margin="normal" required fullWidth id="" label="" name=""
					value={tempResponses[q.id]}
					onChange={(e) => {handleOnChangeResponseValue(e,q)}}
				/>
			  </>}
			  
			  {q.type=='number' &&
			  <>
				<TextField inputProps={{ type: 'number'}}  margin="normal" required fullWidth id="" label="" name=""
					value={tempResponses[q.id]}
					onChange={(e) => {handleOnChangeResponseValue(e,q)}}
				/>			
			  </>}
			  
			  {q.type=='time' &&
			  <>
				 <LocalizationProvider  dateAdapter={AdapterDayjs}>
				  <DemoContainer sx={{ m: 1, width: '500px'}} components={['TimePicker']}>
					<TimePicker
					  name=""
					  label=""
					  value={timeNow}
					  ampm={false}
					  onChange={(e) => {handleOnChangeResponseValue(e,q)}}
					/>
				  </DemoContainer>
				</LocalizationProvider>
				
				</>}
				
				{q.type=='date' &&
			  <>
				 <LocalizationProvider  dateAdapter={AdapterDayjs}>
				  <DemoContainer sx={{ m: 1, width: '500px'}} components={['DatePicker']}>
					<DatePicker 
					  name=""
					  label=""
					  format="DD/MM/YYYY"
					  value={dateNow}
					  ampm={false}
					  onChange={(e) => {handleOnChangeResponseValue(e,q)}}
					/>
				  </DemoContainer>
				</LocalizationProvider>
				
				</>}
				
				 {q.type=='comment' &&
				 <>			
				<TextField  id=""  label="" name=""  multiline rows={4}  sx={{ width: '100%' }}
					value={tempResponses[q.id]}
					onChange={(e) => {handleOnChangeResponseValue(e,q)}}
					inputProps={{ maxlength: q.settings.max_length }}
				/>
				{tempResponses[q.id][0] && <>
					{tempResponses[q.id][0].length}/{q.settings.max_length } Max char
				</>}
				{!tempResponses[q.id][0] && <>
					0/{q.settings.max_length } Max char
				</>} 						  
							
				</>}
			  {q.type=='single_choice' &&
			  <>
			  <FormControl>
				  <FormLabel id="demo-radio-buttons-group-label"></FormLabel>
				  <RadioGroup
					aria-labelledby="demo-radio-buttons-group-label"
					//defaultValue="unknown"
					name="radio-buttons-group"
					onChange={(e) => {handleOnChangeResponseValue(e,q)}}
				  >
				  {q.choices.map((opt,index) => (
					<FormControlLabel value={opt} control={<Radio />} label={opt} />
				  ))}
				  </RadioGroup>
				</FormControl>
				</>}
				
			{q.type=='multi_choice' &&
			  <>
				<FormGroup
					onChange={(e) => {handleOnChangeResponseValue(e,q)}}
				>
				{q.choices.map((opt,index) => (
				  <FormControlLabel value={opt} control={<Checkbox />} label={opt} />
				   ))}
				</FormGroup>
				</>}

						</Item>
				  </Grid>
				))}
			  </Grid>
			</Box>
			<br/>
			 <Button variant="contained" onClick={(e) => handleViewQuesionnaireSubmit(e)}  >
				Submit
			  </Button>

		</>
		:<></>}

		{/* Clone */}
		{(pageMethod=='Clone') ?
		<>
		
		<h4>Title</h4>
		<TextField  margin="normal" required fullWidth id="title" label="Title" name="title"
			value={tempQuestionnaireData['title']}
			onChange={(e) => {handleOnChangeValue(e,'title')}}
		/>
	
			{/*<h4>Description</h4>
		<TextField  id="description"  label="Description" name="Description"  multiline rows={3}  sx={{ width: '100%' }}
			value={tempQuestionnaireData['description']}
			onChange={(e) => {handleOnChangeValue(e,'description')}}
		/>*/}
		
		<div style={{ width: '100%' }}>
			<Box sx={{ display: 'flex', flexDirection: 'row-reverse',  p: 1,  m: 1, borderRadius: 1, }} >	
				<Button onClick={(e) => handleSubmitQuestionnaire(e,'Clone')}  variant="contained" startIcon={<SaveIcon/>}>
				  Clone
				</Button>
			</Box>
		</div>


		</>
		:<></>}
	

				
				{/*<div style={{ width: '100%' }}>
			<Box sx={{ display: 'flex', flexDirection: 'row-reverse',  p: 1,  m: 1, borderRadius: 1, }} >	
				<Button onClick={(e) => handleSubmitNewQuestionnaire(e)}  variant="contained" startIcon={<SaveIcon/>}>
				  Save
				</Button>
			</Box>
	</div>*/}
		
	<br/>
	</>
	:<></>}
	
	{/* Researcher */}
	{(currentUser.__t=='Researcher') ?
	<>
	
	</>
	:<></>}

	</AppLogout>
	</>
  ) 
  ;
};

export default Questionnaire;