import {useEffect,useState} from 'react';
import { useNavigate } from "react-router-dom";

import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TopPrivateNavigation from '../components/TopPrivateNavigation.jsx'
import AppLogout from '../components/AppLogout.jsx'
import ImportExportIcon from '@mui/icons-material/ImportExport';
import Box from '@mui/material/Box';

import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import EditIcon from '@mui/icons-material/Edit';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DeleteIcon from '@mui/icons-material/Delete';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import PreviewIcon from '@mui/icons-material/Preview';
import TableRowsOutlinedIcon from '@mui/icons-material/TableRowsOutlined';
import GridViewOutlinedIcon from '@mui/icons-material/GridViewOutlined';
import CircleIcon from '@mui/icons-material/Circle';
import TextField from '@mui/material/TextField';

import Badge from '@mui/material/Badge';
import MailIcon from '@mui/icons-material/Mail';

//services
import AuthService from '../service/auth'
import ProjectService from '../service/Project'
import QuestionnaireService from '../service/Questionnaire'
import ClientQuestionnaireService from '../service/ClientQuestionnaire'

const formatJson=function(obj){
	return JSON.stringify(obj);
}

const formatDate=function(d){
	var d = new Date(d);
	Number.prototype.padLeft = function(base,chr){
    var  len = (String(base || 10).length - String(this).length)+1;
    return len > 0? new Array(len).join(chr || '0')+this : this;
}
    var dformat = [
               d.getDate().padLeft(),
			   (d.getMonth()+1).padLeft(),
               d.getFullYear()].join('/') +' ' +
              [d.getHours().padLeft(),
               d.getMinutes().padLeft()].join(':');
	return dformat;
}

const Questionnaires = () => {
	
	const navigate = useNavigate();
	//protected page check if logged in
	const [currentUser,setCurrentUser]=useState({});
	const [projectData,setProjectData]=useState([]);
	const [projectFound,setProjectFound]=useState(false);
	
	//let params = useParams();
	let project_id =ProjectService.getCurrentProject();//params.id;
	console.log('project_id',project_id);
		
	useEffect(() => {
		if(!AuthService.isUserLoggedIn()){
			// not logged in navigate back to login
			navigate("/login");
		}else{
				setCurrentUser(AuthService.getCurrentUser());
				var account_type=AuthService.getCurrentUser().__t;
				//console.log('account_type',account_type);
				
				//console.log(AuthService.getCurrentUser());
				var currentUser=AuthService.getCurrentUser();
				//expired Account
				if(currentUser.expired){
					var string = "account_type="+AuthService.getCurrentUser().__t+"&type=expired";
					//console.log('string',string);
					var encodedString = btoa(string); // Base64 encode the String
					//console.log('encodedString',encodedString);
					navigate("/restricted/"+encodedString);
					
				}else{//unverified account
					if(!currentUser.email_verified){
						var string = "account_type="+AuthService.getCurrentUser().__t+"&type=emaiverification";
						//console.log('string',string);
						var encodedString = btoa(string); // Base64 encode the String
						//console.log('encodedString',encodedString);
						navigate("/restricted/"+encodedString);
						
					}else{////force change Password
						if(currentUser.force_password_change){
							//not implemented	
						
						}else{
							//load project if set 
							if((typeof project_id !=="undefined")&&(project_id!==null)){//project filter
								loadProjectById(project_id);
							}
							//load
							if(account_type=='Admin'){//Admin
						
							}else if(account_type=='Master'){//Master
								
								loadQuestionnaires();
								
							}else if(account_type=='Researcher'){//Researcher
								
							}
						}
					}
				}	
		}
	}, []);
	
	//load project 
	function loadProjectById(project_id){
		ProjectService.getById(project_id).then(function(res){
		setProjectData(res.data);
		console.log(res.data);
		setProjectFound(true);
		}).catch(function(error){
			console.log(error);
			setProjectFound(false);
		});
	}
	
	//load questionnaires
	const [questionnaireData,setQuestionnaireData]=useState([]);
	function loadQuestionnaires(){
		var questionnaireFilter ={'global':true}
		if((typeof project_id !=="undefined")&&(project_id!==null)){//project filter
			questionnaireFilter ={'global':false,'project':project_id}
		}
		//questionnaireFilter.active=true;
		console.log(questionnaireFilter);
		QuestionnaireService.getByFilter(questionnaireFilter).then(function(res){
			setQuestionnaireData(res.data);
			console.log(res.data);
			let _questionnaires= res.data;
			let _array={};
			for(let i=0;i<_questionnaires.length;i++){
				console.log('_questionnaires',_questionnaires[i].id);
				_array[_questionnaires[i].id]={'total':0};
				/*setQuestionnaireResponses({...questionnaireResponses,
					[_questionnaires[i].id]: {'total':0},
				})*/
				loadQuestionnaireResponses(_questionnaires[i].id);
			}
			setQuestionnaireResponses(_array);
		}).catch(function(error){
			console.log(error);
		});
	}
	
	function loadQuestionnaireResponses(id){
		console.log('id',id);
		ClientQuestionnaireService.getByFilter({'questionnaire':id}).then(function(res){
			console.log(res.data);
			let _responses=res.data;
			if(_responses.length>0){
				let _questionnaireId=_responses[0].questionnaire;
				setQuestionnaireResponses(questionnaireResponses => ({
					...questionnaireResponses,
					[_questionnaireId]: {'total':_responses.length},
				}))
			}
		}).catch(function(error){
			console.log(error);
		});
	}
	
	/*switch view*/
	const [dataView, setDataView] = useState('table');
	const handleSwitchView = (e,view) => {
			setDataView(view)
	
	};
	
	/* sort fields */
	const [sortByField, setSortByField] = useState('date');
	const [sortType, setSortType] = useState('asc');//desc
	
	const handleSortQuestionnaires = (e,sortByField) => {
	   console.log('default','sortByField',sortByField,'sortType',sortType);
	   let sorted;
	   
	   //field
	   if(sortByField=='date'){
		   console.log('sort by date.....');
		  		 if(sortType=='asc'){
		    sorted = [...questionnaireData].sort((a, b) => {
			   var keyA = new Date(a.last_updated),
					keyB = new Date(b.last_updated);
				  if (keyA < keyB) return -1;
				  if (keyA> keyB) return 1;
				  return 0;
			});
		   
		   setSortType('desc');
		}else if(sortType=='desc'){
		      sorted = [...questionnaireData].sort((a, b) => {
			    var keyA = new Date(a.last_updated),
					keyB = new Date(b.last_updated);
				  if (keyA > keyB) return -1;
				  if (keyA< keyB) return 1;
				  return 0;
				});
				   setSortType('asc');
		}
	   }else if(sortByField=='title'){
		  console.log('sort by title.....');
		 if(sortType=='asc'){
		    sorted = [...questionnaireData].sort((a, b) => {
			   var keyA = a.title,
					keyB = b.title;
				  if (keyA < keyB) return -1;
				  if (keyA> keyB) return 1;
				  return 0;
			});
		   
		   setSortType('desc');
		}else if(sortType=='desc'){
		      sorted = [...questionnaireData].sort((a, b) => {
			   var keyA = a.title,
					keyB = b.title;
				  if (keyA > keyB) return -1;
				  if (keyA< keyB) return 1;
				  return 0;
				});
				   setSortType('asc');
		}
	   }else{
		  /// console.log('dont know what to sort by.....');
	   }

    setQuestionnaireData(sorted);
  };
  
  /*end sort fields */
  
  
  /*delete questionnaire modal*/
  const [openDeleteQuestionnaireModal, setOpenDeleteQuestionnaireModal] = useState(false);
  const [tempQuestionnaire, setTempQuestionnaire] = useState({});

  const handleDeleteQuestionnaireModal = (e,questionnaire) => {
	  setTempQuestionnaire(questionnaire);
	  console.log('questionnaire',questionnaire);
		setOpenDeleteQuestionnaireModal(true);
	  };

	  const handleDeleteClose = () => {
		setOpenDeleteQuestionnaireModal(false);
	  };
	  
	  const handleDeleteQuestionnaire=()=>{
		//setOpenDeleteQuestionnaireModal(false);
		console.log('tempQuestionnaire',tempQuestionnaire);
		//tempQuestionnaire.active=false;
		QuestionnaireService.deleteById(tempQuestionnaire.id).then(function(res){
		//setQuestionnaireData(res.data);
		console.log(res.data);
		setOpenDeleteQuestionnaireModal(false);
		loadQuestionnaires();
		}).catch(function(error){
			console.log(error);
		});
	  };
	  
	/*Clone questionnaire modal*/
	const [openCloneQuestionnaireModal, setOpenCloneQuestionnaireModal] = useState(false);

  const handleCloneQuestionnaireModal = (e,questionnaire) => {
	  setTempQuestionnaire(questionnaire);
	  console.log('questionnaire',questionnaire);
		setOpenCloneQuestionnaireModal(true);
	  };

	  const handleCloneClose = () => {
		setOpenCloneQuestionnaireModal(false);
	  };
	  
	  const handleCloneQuestionnaire=()=>{//deactivate not delete
		//setOpenDeleteQuestionnaireModal(false);
		console.log('tempQuestionnaire',tempQuestionnaire);
		//tempQuestionnaire.active=false;
		QuestionnaireService.clone(tempQuestionnaire).then(function(res){
		//setQuestionnaireData(res.data);
		console.log(res.data);
		setOpenCloneQuestionnaireModal(false);
		loadQuestionnaires();
		}).catch(function(error){
			console.log(error);
		});
	  };
	  
	  const handleOnChangeValue = (e,key) => {
		console.log(e.target.value,key);
		setTempQuestionnaire({...tempQuestionnaire,
			[key]: e.target.value,
		})
	};
	
		/*update questionnaire modal*/
	const [openUpdateQuestionnaireModal, setOpenUpdateQuestionnaireModal] = useState(false);

  const handleUpdateQuestionnaireModal = (e,questionnaire) => {
	  setTempQuestionnaire(questionnaire);
	  console.log('questionnaire',questionnaire);
		setOpenUpdateQuestionnaireModal(true);
	  };

	  const handleUpdateClose = () => {
		setOpenUpdateQuestionnaireModal(false);
	  };
	  
	  const [updateQuestionnaireMessage, setUpdateQuestionnaireMessage] = useState({"message":""});
	  const handleUpdateQuestionnaire=(e,questionnaire)=>{
		setTempQuestionnaire(questionnaire);
		console.log('tempQuestionnaire',tempQuestionnaire);
		//tempQuestionnaire.active=false;
		//setOpenUpdateQuestionnaireModal(false);
		if(questionnaire.active){
			questionnaire.active=false;
			//setUpdateQuestionnaireMessage(updateQuestionnaireMessage => ({
			//		...updateQuestionnaireMessage,
			//		"message": "Are you sure you want to turn the visibility of this questionnaire Off?",
			//}))
		}else{
			questionnaire.active=true;
			//setUpdateQuestionnaireMessage(updateQuestionnaireMessage => ({
			//		...updateQuestionnaireMessage,
			//		"message": "Are you sure you want to turn the visibility of this questionnaire On?",
			//}))
		}
		QuestionnaireService.update(questionnaire).then(function(res){
		//setQuestionnaireData(res.data);
		console.log(res.data);
		setOpenUpdateQuestionnaireModal(false);
		loadQuestionnaires();
		}).catch(function(error){
			console.log(error);
		});
	  };
	  
	const [questionnaireResponses,setQuestionnaireResponses]=useState({});
	
  return(
  <>
	<TopPrivateNavigation/>
	<AppLogout>
	
		{/* error no project found */}
	{(!projectFound) ?
		<>
		
		<br/>
		<Breadcrumbs aria-label="breadcrumb">
        <Link underline="hover" color="inherit" href="/">
          Home
        </Link>
		 <Link
          underline="hover"
          color="inherit"
          href={`/Questionnaires`}
        >
          Questionnaires
        </Link>
      </Breadcrumbs>
	  
	  <br/>
	  <Divider />
		<br/>
		
		
		</>
	:<></>}
	{/* if project is found */}
	{(projectFound) ?
	<>
		<br/>
		<Breadcrumbs aria-label="breadcrumb">
        <Link underline="hover" color="inherit" href="/">
          Home
        </Link>
        <Link
          underline="hover"
          color="inherit"
          href="/projects"
        >
          Projects
        </Link>
		<Link
          underline="hover"
          color="inherit"
          href={`/project/${projectData._id}`}
        >
          {projectData.name}
        </Link>
		<Link
          underline="hover"
          color="inherit"
          href={`/Questionnaires`}
        >
          Questionnaires
        </Link>
      </Breadcrumbs>
	  
	  <br/>
	  <Divider />
		<br/>
	
	</>
	:<></>}
	
	{/* Admin */}
	{(currentUser.__t=='Admin') ?
	<>
	
	</>
	:<></>}
	
	{/* Master */}
	{(currentUser.__t=='Master') ?
	<>

	<Button href={`/questionnaire/New`}  variant="contained" endIcon={<AddIcon />}  >
		New Questionnaire 
	</Button>
	<br/><br/>
	{/* Master */}
	{(questionnaireData.length==0) ?
	<>No questionnaires found</>
	:<>
	
	{/*start sort buttons */}
	<div style={{ width: '100%' }}>
      <Box sx={{ display: 'flex', flexDirection: 'row-reverse', p: 1, m: 1, borderRadius: 1}}>
		{dataView=='grid'?
		<>
			<Button  size="small" onClick={(e) => handleSwitchView(e,"table")}  variant="outlined" endIcon={<TableRowsOutlinedIcon/>}>View</Button>&nbsp;
		</>:<>
			<Button  size="small" onClick={(e) => handleSwitchView(e,"grid")} variant="outlined" endIcon={<GridViewOutlinedIcon/>}>View</Button>&nbsp;
		</>}
	 
        <Button  size="small" onClick={(e) => handleSortQuestionnaires(e,"date")}  variant="outlined" endIcon={<ImportExportIcon/>}>
		  Sort new/old
		</Button>&nbsp;
		<Button size="small" onClick={(e) => handleSortQuestionnaires(e,"title")}  variant="outlined" endIcon={<ImportExportIcon/>}>
		  Sort a/z
		</Button>
      </Box>
    </div>
	
	 {/*end sort buttons */}
	 
	 {/*   delete questionnaire dialog */}
	 <Dialog
        open={openDeleteQuestionnaireModal}
        onClose={handleDeleteClose}
        //aria-labelledby="alert-dialog-title"
		//aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Are you sure you want to delete this questionnaire?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
		  
          </DialogContentText>
        </DialogContent>
        <DialogActions>
			<Button onClick={handleDeleteClose}>No</Button>
			<Button onClick={handleDeleteQuestionnaire} > Yes</Button>
        </DialogActions>
      </Dialog>
	  
	 {/*   clone questionnaire dialog */}
	 <Dialog
        open={openCloneQuestionnaireModal}
        onClose={handleCloneClose}
        //aria-labelledby="alert-dialog-title"
		//aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Are you sure you want to clone this questionnaire?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
		  <h4>Title</h4>
		<TextField  margin="normal" required fullWidth id="title" label="Title" name="title"
			value={tempQuestionnaire['title']}
			onChange={(e) => {handleOnChangeValue(e,'title')}}
		/>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
			<Button onClick={handleCloneClose}>Cancel</Button>
			<Button onClick={handleCloneQuestionnaire} > Yes</Button>
        </DialogActions>
      </Dialog>
	  
	 {/*   clone questionnaire dialog */}
	 <Dialog
        open={openUpdateQuestionnaireModal}
        onClose={handleUpdateClose}
        //aria-labelledby="alert-dialog-title"
		//aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {updateQuestionnaireMessage.message}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">

          </DialogContentText>
        </DialogContent>
        <DialogActions>
			<Button onClick={handleUpdateClose}>Cancel</Button>
			<Button onClick={handleUpdateQuestionnaire} > Yes</Button>
        </DialogActions>
      </Dialog>
	  
	
	  {(dataView=='table')?
	  <>
	  <TableContainer component={Paper}>
		<Table sx={{ minWidth: 650 }} aria-label="simple table">
			<TableHead>
						  <TableRow>
						
							<TableCell>Title</TableCell>
							{/*<TableCell>Description</TableCell>*/}
							<TableCell>Created By</TableCell>
							<TableCell>Last Updated</TableCell>
							<TableCell align="center">Published</TableCell>
							<TableCell align="center">Responses</TableCell>
							<TableCell></TableCell>
						  </TableRow>
						</TableHead>
						<TableBody>
						  {questionnaireData.map((row,index) => (
							<TableRow
							  key={index}
							  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
							>
						
							  <TableCell component="th" scope="row">
								{row.title}
							  </TableCell>
							  {/*<TableCell component="th" scope="row">
								{row.description}
						  </TableCell>*/}
							  <TableCell component="th" scope="row">
								{row.created_by.first_name} {row.created_by.last_name}
							  </TableCell>
							  <TableCell component="th" scope="row">
								{formatDate(row.last_updated)}
							  </TableCell>
							  <TableCell component="th" scope="row" align="center">
								 {(row.published==false) ?
									<>
									<CircleIcon  fontSize="small"  style={{  color: 'orange',fontSize:"small" }} /> 
									</>
									:<>
									<CircleIcon    style={{  color: 'green',fontSize:"small" }} /> 
								</>}
							  </TableCell>
							  <TableCell component="th" scope="row" align="center">
								{row.published==true &&
								<>
									<Badge badgeContent={questionnaireResponses[row.id].total} color="primary">
									  <MailIcon color="action" />
									</Badge>
								</>}
								
							  </TableCell>
							 
							   <TableCell component="th" scope="row" align="right">
							   {row.published==false &&
							   <>
							   <Tooltip title="Edit">
								   <IconButton href={`/questionnaire/edit/${row._id}`} aria-label="edit">
									<EditIcon />
								  </IconButton>
								</Tooltip>
							   <Tooltip title="Preview">
								  <IconButton href={`/questionnaire/view/${row._id}`} aria-label="view">
									<PreviewIcon />
								  </IconButton>
								</Tooltip>
								
								<Tooltip title="Clone">
								  <IconButton onClick={(e) => handleCloneQuestionnaireModal(e,row)} aria-label="copy">
									<ContentCopyIcon />
								  </IconButton>
								</Tooltip>
								
								<Tooltip title="Delete">
								   <IconButton onClick={(e) => handleDeleteQuestionnaireModal(e,row)} aria-label="delete">
									<DeleteIcon />
								  </IconButton>
								</Tooltip>
							   
							   </>}
							   
							   {row.published==true &&
							   <>
							   
							   <Tooltip title="Preview">
								  <IconButton href={`/questionnaire/view/${row._id}`} aria-label="view">
									<PreviewIcon />
								  </IconButton>
								</Tooltip>
								<Tooltip title="Clone">
								  <IconButton onClick={(e) => handleCloneQuestionnaireModal(e,row)} aria-label="copy">
									<ContentCopyIcon />
								  </IconButton>
								</Tooltip>
								{row.active==true &&
									<>
									<Tooltip title="turn off visibility">
									  <IconButton onClick={(e) => handleUpdateQuestionnaire(e,row)} aria-label="copy">
										<VisibilityIcon />
									  </IconButton>
									</Tooltip>
									</>}
									{row.active==false &&
									<>
									<Tooltip title="turn on visibility">
									  <IconButton onClick={(e) => handleUpdateQuestionnaire(e,row)} aria-label="copy">
										<VisibilityOffIcon />
									  </IconButton>
									</Tooltip>
									</>}
							   </>}
								
								
							  </TableCell>
							  
							</TableRow>
						  ))}
						</TableBody>
					  </Table>
					</TableContainer>
	  
	  </>
	  :<>
	  
	  
	   <Grid container spacing={3} >
			  {questionnaireData.map((row,index) => (
			 <Grid key={row._id} item item xs={12} sm={6} md={4} >
				 <Card variant="outlined" sx={{ minWidth: 25,minHeight:240 }} >
				
				<CardHeader style={{ textAlign: 'center' }} 
					title={row.title}
					action={<IconButton disabled aria-label="settings">
								{(row.published) ?
								<>
								<CircleIcon  fontSize="small"  style={{  color: 'green',fontSize:"small" }} /> 
								</>
								:<>
								<CircleIcon    style={{  color: 'orange',fontSize:"small" }} /> 
								 </>}
								 </IconButton>
							}
				  />
					<CardContent style={{ textAlign: 'center' }} >
						{/*<Typography sx={{ mb: 1.5 }} color="text.secondary">
							{row.description}
			  </Typography>*/}
						<Typography variant="body2">
						<b>Created By:</b> {row.created_by.first_name} {row.created_by.last_name}
						<br />
						<b>Last updated:</b>{formatDate(row.last_updated)}
						<br />
						</Typography>
					</CardContent>
					<CardActions style={{justifyContent:'center'}}>
							{row.published==false &&
							<>
							
								<Tooltip title="Edit">
								   <IconButton href={`/questionnaire/edit/${row._id}`} aria-label="edit">
									<EditIcon />
								  </IconButton>
								</Tooltip>
								<Tooltip title="Preview">
								  <IconButton href={`/questionnaire/view/${row._id}`} aria-label="view">
									<PreviewIcon />
								  </IconButton>
								</Tooltip>
								<Tooltip title="Clone">
								  <IconButton onClick={(e) => handleCloneQuestionnaireModal(e,row)} aria-label="copy">
									<ContentCopyIcon />
								  </IconButton>
								</Tooltip>
								<Tooltip title="Delete">
								   <IconButton onClick={(e) => handleDeleteQuestionnaireModal(e,row)} aria-label="delete">
									<DeleteIcon />
								  </IconButton>
								</Tooltip>
							</>}
							
							{row.published==true &&
							<>
							<Tooltip title="Preview">
								  <IconButton href={`/questionnaire/view/${row._id}`} aria-label="view">
									<PreviewIcon />
								  </IconButton>
								</Tooltip>
								<Tooltip title="Clone">
								  <IconButton onClick={(e) => handleCloneQuestionnaireModal(e,row)} aria-label="copy">
									<ContentCopyIcon />
								  </IconButton>
								</Tooltip>
									{row.active==true &&
									<>
									<Tooltip title="turn off visibility">
									  <IconButton onClick={(e) => handleUpdateQuestionnaire(e,row)} aria-label="copy">
										<VisibilityIcon />
									  </IconButton>
									</Tooltip>
									</>}
									{row.active==false &&
									<>
									<Tooltip title="turn on visibility">
									  <IconButton onClick={(e) => handleUpdateQuestionnaire(e,row)} aria-label="copy">
										<VisibilityOffIcon />
									  </IconButton>
									</Tooltip>
									</>}
							</>}
							
				  </CardActions>
			</Card>	
			</Grid>
			
			))}
		</Grid>
	  
	  </>}
	
	</>}
		
	
	
	
	</>
	:<></>}
	
	{/* Researcher */}
	{(currentUser.__t=='Researcher') ?
	<>
	
	</>
	:<></>}

	</AppLogout>
	</>
  ) 
  ;
};

export default Questionnaires;